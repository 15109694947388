body {
  background-color: #186464 !important;
  color: #fff !important;
  padding-bottom: 50px;
  padding-top: 70px;
}

img {
  width: 100%;
  height: auto;
}

.text-justify {
  text-align: justify !important;
}

.text-justify span{
  color: #fff !important;
}

.text-justify strong{
  color: #fff !important;
  font-weight: bolder !important;
}

.text-justify a strong {
  color: #ffffff !important;
  font-weight: bolder !important;
  text-decoration: none !important;
}

.text-justify a {
  color: #ffffff !important;
  text-decoration: underline !important;
}

.logo_ayp {
  width: 50px;
  height: auto;
}

.navbar-dark{
  background-color: #414141 !important;
}

.navbar button{
  background: none;
  border:none;
}

.btn-logout{
  background-color: #414141 !important;
  border-color: #414141 !important;
}

.btn-primary:hover{
  background-color: #186464 !important;
}

.btn-primary:after{
  background-color: #414141 !important;
}

.sidebar{
  position: absolute;
  top: 66px;
  left: 0;
  width: 100% !important;
  height: 90%;
  z-index: 1000;
}

.bg-sidebar{
  background-color: #414141;
}

.modal-header, .modal-body, .modal-footer{
  background-color: #414141 !important;
}

.btn-size {
  background-color: #414141 !important;
  border: 1px solid #ffffff !important;
  height: 60px;
  width: 60px;
  box-sizing: border-box;
  padding: 10px;
}

.btn-size:hover {
  background-color: #186464;
  border-color: #186464 !important;
}

.btn-size-lg{
  font-size: 25px !important;
}

.btn-size-md{
  font-size: 18px !important;
}

.btn-size-sm{
  font-size: 14px !important;
}

.btn-vista {
  background-color: #414141 !important;
  border: 1px solid #ffffff !important;
  height: 40px;
  padding: 10px;
}

.selecionado{
  background-color: #186464 !important;
  border-color: #186464 !important;
}

.card{
  background-color: #0D4F83 !important;
  border-color: #0D4F83 !important;
}

.list-group-item{
  background-color: #338E8A !important;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #fff !important;
}

.redondeado{
  border-radius: 50% !important;
}

.resultBelt{
  background-color: #0D4F83 !important;
}

.hightlight{
  background-color: #0D4F83 !important;
  color: #ffffff !important;
}

.flotante{
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 0px !important;
  border-radius: 50% !important;
  padding: 10px !important;
  z-index: 100 !important;
  font-size: 2em !important;
  background-color: #414141 !important;
}

.flotante:hover{
  background-color: #0D4F83 !important;
}

.btn-close{
  border-color: #ffffff;
}

.ql-align-justify span{
  color:  #ffffff !important;
}

.ql-align-justify a{
  color:  #ffffff !important;
  font-weight: bolder !important;
  text-decoration: underline !important;
}

.card{
  cursor: pointer !important;
}

.resaltado {
  background: yellow !important;
  color: black !important;
  font-weight: bolder !important;
}